import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CliPage = () => (
  <Layout>
    <SEO title="Cli" />
    <div className="edge force">
        <h1>CLI</h1>
        <p className="text-preset-large-intro">CLI is coming soon!</p><p>Can't wait? Go to the <a href="https://github.com/HarwinBorger/SpaceFramework-CLI" target="_blank" rel="noreferrer">Github Repository</a></p>
    </div>
  </Layout>
)

export default CliPage
